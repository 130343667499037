import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  HookComponentProvider,
  ContactForm,
  LocationSelect,
  Content,
  LocationsContext,
  Map,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { useContext } from "react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Background
            columns={1}
            stackable={true}
            className={"contact-background"}
          >
            <Background.Pattern type={"CIRCLE"}>
              <Grid
                className={"contact-grid"}
                stackable={true}
                doubling={true}
                columns={2}
              >
                <Grid.Column className={"contact-form-column"}>
                  <HookComponentProvider
                    hook={useContext}
                    component={
                      <ContactForm
                        buttonText={"Submit"}
                        fields={[
                          {
                            label: "name",
                            placeholder: "Name",
                            type: "input",
                            required: true,
                          },
                          {
                            label: "email",
                            placeholder: "Email",
                            type: "input",
                            inputType: "email",
                            required: true,
                          },
                          {
                            label: "phone",
                            placeholder: "Phone Number",
                            type: "input",
                            required: true,
                          },
                          {
                            displayLabel: "Message",
                            label: "message",
                            placeholder: "Message",
                            type: "textarea",
                            required: true,
                          },
                        ]}
                        centeredHeaders={true}
                        hideOptIn={false}
                        className={""}
                        fluidButton={true}
                        header={
                          <div className={"contact-form-header"}>
                            <div>
                              <Header
                                as={"h1"}
                                content={getComponentContentNodeContent({
                                  components:
                                    fishermanBusinessWebsitePage.components,
                                  componentId: "contact_form_header",
                                  defaultValue: "CONTACT",
                                })}
                              />
                            </div>
                            <div>
                              <LocationSelect
                                useContext={true}
                                search={false}
                              />
                            </div>
                            <Content textAlign={"left"}>
                              <Content.Markup
                                width={16}
                                className={""}
                                isMarkdown={true}
                                content={getComponentContentNodeContent({
                                  components:
                                    fishermanBusinessWebsitePage.components,
                                  componentId: "contact_form_description",
                                })}
                              />
                            </Content>
                          </div>
                        }
                        inline={false}
                        showLabels={true}
                        subheader={""}
                        termsCopy={""}
                        withContainer={false}
                        businessId={fishermanBusiness._id}
                        businessType={fishermanBusiness.type}
                      />
                    }
                    hookToPropsMap={{ locationId: "activeLocation.id" }}
                    hookParams={[LocationsContext]}
                  />
                </Grid.Column>
                <Grid.Column className={"map-column"}>
                  <HookComponentProvider
                    hook={useContext}
                    component={Map}
                    hookToPropsMap={{
                      "address.street": "activeLocation.street",
                      "address.city": "activeLocation.city",
                      "address.state": "activeLocation.state",
                      "address.zip": "activeLocation.zipCode",
                    }}
                    hookParams={[LocationsContext]}
                    onlyRenderOnClientSide={true}
                  />
                </Grid.Column>
              </Grid>
            </Background.Pattern>
          </Background>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
